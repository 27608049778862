<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="店铺名称：">
                <el-input size="small" v-model="searchForm.store_name" placeholder="请输入店铺名称"></el-input>
            </el-form-item>
            <el-form-item label="wifi名称：">
                <el-input size="small" v-model="searchForm.wifi_name" placeholder="请输入wifi名称"></el-input>
            </el-form-item>
            <el-form-item label="unionid：">
                <el-input size="small" v-model="searchForm.unionid" placeholder="请输入unionid"></el-input>
            </el-form-item>
            <el-form-item label="服务商姓名：">
                <el-input size="small" v-model="searchForm.facilitator_name" placeholder="请输入服务商姓名"></el-input>
            </el-form-item>
            <el-form-item label="链接时间 ：">
                <el-date-picker size="small" v-model="searchForm.time" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" size="small" @click="getList(1)">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="wifi_info.wifi_name" label="wifi名称" align="center" width="90"></el-table-column>
            <el-table-column prop="store_info.store_name" label="店铺名称" align="center" width="90"></el-table-column>
            <el-table-column prop="unionid" label="用户unionid" align="center"></el-table-column>
            <el-table-column prop="contact_name" label="用户信息" width="130" align="center">
                <template v-slot="{ row }">
                    <userInfo :name="row.user_info ? row.user_info.nickname : '微信用户'"
                        :avatar="row.user_info ? row.user_info.headimgurl : 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/beauty/defaultHeaderImg.png'">
                    </userInfo>
                </template>
            </el-table-column>
            <el-table-column prop="store_wifi_count" label="wifi连接次数" align="center" width="100"></el-table-column>
            <el-table-column prop="wifi_count" label="用户总连接次数" align="center" width="100">
                <template v-slot="{ row }">
                    <div style="color: red; font-weight: bold;">
                        {{ row.wifi_count }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="direct_gift_wifi_count" label="今日链接直接奖励" align="center" width="100">
                <template v-slot="{ row }">
                    <div style="color: red; font-weight: bold;">
                        {{ row.direct_gift_wifi_count }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="person_report" label="是否封印" align="center" width="90">
                <template v-slot="{ row }">
                    <div style="color: red; font-weight: bold;">
                        {{ row.person_report ? "是" : "否" }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="device_no" label="设备型号 " align="center" width="90">
                <template v-slot="{ row }">
                    <div>
                        {{ row.device_no ? row.device_no : "--" }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="brand" label="设备品牌 " align="center" width="90">
                <template v-slot="{ row }">
                    <div>
                        {{ row.brand ? row.brand : "--" }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="type_from" label="连接方式" align="center" width="190">
                <template v-slot="{ row }">
                    <div>
                        {{ row.type_from == 0 ? "正常连wifi入口" : row.type_from == 1 ? "扫码直接赠送" : row.type_from == 2 ?
                            "已经链接wifi赠送" : row.type_from == 3 ? "没限制看完广告直接送奖金" : "--" }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="wifi_info.username" label="wifi账号" align="center"></el-table-column>
            <el-table-column prop="wifi_info.password" label="wifi 密码" align="center"></el-table-column>
            <el-table-column prop="facilitator_info.name" label="服务商姓名" align="center"></el-table-column>
            <el-table-column prop="facilitator_info.mobile" label="服务商电话" align="center"></el-table-column>
            <el-table-column prop="mobile" label="连接时间" align="center">
                <template v-slot="{ row }">
                    {{ getDateformat(row.create_time) }}
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
    </el-main>
</template>

<script>
import userInfo from '@/components/userInfo';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import _ from 'lodash';
export default {
    components: {
        userInfo,
        Paging,
    },
    data () {
        return {
            getDateformat,
            searchForm: {
                page: 1,
                rows: 10,
                store_name: '',
                wifi_name: '',
                facilitator_name: '',
                time: [],
                unionid: '',
            },
            list: [],
            total_number: 0,
        };
    },
    created () {
        this.getList();
    },
    methods: {
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                store_name: '',
                wifi_name: '',
                time: [],
                unionid: '',
                facilitator_name: '',
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        getList (style) {
            if (style) {
                this.searchForm.page = 1;
            }
            let searchForm = {
                page: this.searchForm.page,
                rows: this.searchForm.rows,
            };
            if (this.searchForm.store_name) searchForm.store_name = this.searchForm.store_name;
            if (this.searchForm.wifi_name) searchForm.wifi_name = this.searchForm.wifi_name;
            if (this.searchForm.unionid) searchForm.unionid = this.searchForm.unionid;
            if (this.searchForm.facilitator_name) searchForm.facilitator_name = this.searchForm.facilitator_name;
            if (this.searchForm.time?.length) {
                searchForm.start_time = this.searchForm.time[0].getTime() / 1000;
                searchForm.end_time = this.searchForm.time[1].getTime() / 1000;
                if (searchForm.start_time == searchForm.end_time) searchForm.end_time = searchForm.end_time + 86400;
            }
            this.$axios.post(this.$api.serviceProvider.WifiSettlement, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .sortList {
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}
</style>